import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormikHelpers, useFormik } from "formik";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useRecoilState } from "recoil";

import { VendorType } from "../../../../../../../modules/customer/models/Access";
import { GeneralActivityRequest } from "../../../../../../../modules/game/models/GeneralActivities";

import { useForceDateTimeToSelectedFacilityTimeZone } from "../../../../../../../hooks/datetime/useForceDateTimeToSelectedFacilityTimeZone";
import { useFacilityLocalization } from "../../../../../../../hooks/store/useFacilityLocalization";
import { useVendorType } from "../../../../../../../hooks/swr/useVendorType";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";
import { useFormValidationSchema } from "../../../../../../../modules/game/hooks/useFormValidationSchema";

import { Button } from "../../../../../../../components/Button";
import { CalendarInput } from "../../../../../../../components/CalendarInput";
import { Checkbox } from "../../../../../../../components/Checkbox";
import { Label } from "../../../../../../../components/Label";
import { NumberInput } from "../../../../../../../components/NumberInput";
import { ProgressSpinner } from "../../../../../../../components/ProgressSpinner";
import { RadioButton } from "../../../../../../../components/RadioButton";
import { SkillLevelSlider } from "../../../../../../../components/SkillLevelSlider";
import { TextAreaInput } from "../../../../../../../components/TextAreaInput";
import { TextInput } from "../../../../../../../components/TextInput";
import { SelectInput } from "../../../../../../../components/inputs/SelectInput";
import { CourtPicker } from "../../../../components/form/CourtPicker";
import { GenderPicker } from "../../../../components/form/GenderPicker";

import { DayOfWeek } from "../../../../../../../enums/DayOfWeek";
import { isPinCodeEnabledState } from "../../../../../../../recoil/Admin/activitiy/activityCreationState";
import { CoordinatorSelector } from "../CoordinatorSelector";
import { MembershipPrices } from "../MembershipPrices";

interface Props {
  facilityId: string;
  actionButtons?: React.ReactNode;
  create?: boolean;
  hasChange?: boolean;
  imageSelector?: React.ReactNode;
  initialValues: GeneralActivityRequest;
  onSubmit: (data: GeneralActivityRequest, onError: () => void) => void;
}

export const Form = ({
  actionButtons,
  facilityId,
  create,
  hasChange,
  imageSelector,
  initialValues,
  onSubmit,
}: Props) => {
  const { createGeneralActivitySchema } = useFormValidationSchema();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [enablePinCode, setEnablePinCode] = useRecoilState<boolean>(
    isPinCodeEnabledState,
  );
  const [isOpenDoor, setIsOpenDoor] = useState<boolean>(false);
  const [isCloseDoor, setIsCloseDoor] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>(null);
  const intl = useIntl();
  const { vendorType } = useVendorType(facilityId);

  const { forceDateTimeToSelectedFacilityTimeZone } =
    useForceDateTimeToSelectedFacilityTimeZone();
  const localization = useFacilityLocalization();

  const minDate = DateTime.now().plus({
    minute:
      DateTime.local({ zone: localization?.timeZone }).offset -
      DateTime.now().offset,
  });

  const { df } = useDateFormat(facilityId);

  useEffect(() => {
    if (initialValues?.isPinCodeEnabled) {
      setEnablePinCode(true);
      setIsOpenDoor(false);
      setIsCloseDoor(false);
      setPinCode(initialValues?.pinCode);
    } else if (initialValues?.openDoor) {
      setIsOpenDoor(true);
      setIsCloseDoor(false);
      setEnablePinCode(false);
    } else {
      setIsCloseDoor(true);
      setIsOpenDoor(false);
      setEnablePinCode(false);
    }
  }, []);

  // Formik
  const formik = useFormik<GeneralActivityRequest>({
    validationSchema: createGeneralActivitySchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: (
      data: GeneralActivityRequest,
      actions: FormikHelpers<GeneralActivityRequest>,
    ) => {
      // please stop this madness
      data.startTime = forceDateTimeToSelectedFacilityTimeZone(data.startTime);
      data.endTime = forceDateTimeToSelectedFacilityTimeZone(data.endTime);
      data.registrationOpenTo = forceDateTimeToSelectedFacilityTimeZone(
        data.registrationOpenTo,
      );
      data.automaticCancellationAt = forceDateTimeToSelectedFacilityTimeZone(
        data.automaticCancellationAt,
      );

      return onSubmit(data, () => actions.setSubmitting(false));
    },
  });

  useEffect(() => {
    if (
      formik.values.startTime.toMillis() ===
        initialValues.startTime.toMillis() &&
      !formik.dirty
    ) {
      return;
    }

    const start = formik.values.startTime;
    if (create) {
      formik.setFieldValue("endTime", start.plus({ hours: 1 }));
    }

    formik.setFieldValue("registrationOpenTo", start.minus({ hours: 2 }));
    formik.setFieldValue("automaticCancellationAt", start.minus({ hours: 1 }));
  }, [formik.values.startTime]);

  useEffect(() => {
    if (formik.values.recurringType === "never") {
      formik.setFieldValue("daysOfWeek", [formik.values.startTime.weekday % 7]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.recurringType, formik.values.startTime]);

  const maxCancellationDateTime = useMemo(() => {
    const start = formik.values.startTime;
    return start.minus({ hours: 1 });
  }, [formik.values.startTime]);

  const showSave = formik.dirty || hasChange;

  useEffect(() => {
    vendorType === VendorType.None && setEnablePinCode(false);
  }, [vendorType]);

  const handleDoorConfiguration = (arg: string) => {
    if (arg === "closeDoor") {
      setIsCloseDoor(true);
      setIsOpenDoor(false);
      setEnablePinCode(false);
      setPinCode(null);
    } else if (arg === "openDoor") {
      setIsOpenDoor(true);
      setIsCloseDoor(false);
      setEnablePinCode(false);
      setPinCode(null);
    } else if (arg === "enablePinCode") {
      setEnablePinCode(true);
      setIsOpenDoor(false);
      setIsCloseDoor(false);
    }
  };

  useEffect(() => {
    formik.setFieldValue("openDoor", isOpenDoor);
    formik.setFieldValue("isPinCodeEnabled", enablePinCode);
    formik.setFieldValue("pinCode", pinCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enablePinCode, isOpenDoor, isCloseDoor, pinCode]);

  const [skillLevel, setSkillLevel] = useState<[number, number]>([
    initialValues.minSkillLevel ?? 1,
    initialValues.maxSkillLevel ?? 10,
  ]);

  useEffect(() => {
    if (formik.values.openForRegistration === false && formik.values.hidden) {
      formik.setFieldValue("hidden", false);
    }
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {/** Section 1 */}
      <div>
        <div className="mb-5 flex justify-end gap-x-3 sm:gap-x-5">
          {actionButtons}
          {showSave && (
            <Button
              disabled={formik.isSubmitting}
              type="primary"
              size="small"
              onClick={() => {
                setShowErrors(true);
                formik?.submitForm();
              }}
              text={intl.formatMessage({
                id: create
                  ? "admin.activities.event.create"
                  : "admin.activities.event.save",
              })}
            >
              {formik.isSubmitting && <ProgressSpinner fontSize="1rem" />}
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-5 xl:flex-row">
          {imageSelector}
          <div className="flex grow flex-col">
            <TextInput
              name="name"
              label={intl.formatMessage({
                id: "common.name",
              })}
              value={formik.values.name}
              onChange={formik.handleChange}
              required
              error={showErrors && formik.errors["name"]}
            />
            <TextAreaInput
              className="mt-8 flex-1"
              name="description"
              label={intl.formatMessage({
                id: "common.description",
              })}
              resize="none"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col flex-wrap gap-y-8 sm:flex-row sm:items-end sm:gap-x-20">
          <GenderPicker
            value={formik.values.gender}
            label={intl.formatMessage({
              id: "common.gender",
            })}
            name="gender"
            onChange={formik.setFieldValue}
          />

          <SelectInput
            className="min-w-[200px]"
            id="event-priority-select"
            label={intl.formatMessage({ id: "common.priority" })}
            tooltip={intl.formatMessage({
              id: "admin.activities.event.priority-tooltip",
            })}
            value={formik.values.priority ?? ""}
            options={[
              {
                label: intl.formatMessage({ id: "common.no-priority" }),
                value: "",
              },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
            ]}
            onChange={e => {
              formik.setFieldValue(
                "priority",
                e.target.value ? e.target.value : null,
              );
            }}
          />

          <div className="flex flex-1 flex-col gap-1.5">
            <Label>
              <FormattedMessage id="common.recommended-skill-level" />
            </Label>
            <div className="flex flex-1 items-center pt-[22px] text-lg sm:mt-0 sm:min-w-96">
              <span className="mr-8 w-[1.6em] flex-none text-right font-bold">
                {skillLevel[0].toFixed(1)}
              </span>
              <SkillLevelSlider
                value={skillLevel}
                onChange={(e, value) => {
                  if (!Array.isArray(value)) {
                    return;
                  }

                  setSkillLevel(value as [number, number]);
                }}
                onChangeCommitted={(e, value) => {
                  if (!Array.isArray(value)) {
                    return;
                  }

                  formik.setFieldValue("minSkillLevel", value[0]);
                  formik.setFieldValue("maxSkillLevel", value[1]);
                }}
              />
              <span className="ml-8 w-[1.6em] flex-none font-bold">
                {skillLevel[1].toFixed(1)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="my-10 border-b-[1px] border-gray-50" />

      {/** Section 2 */}
      <div className="grid flex-1 gap-x-5 sm:grid-cols-2 lg:w-1/2">
        <h3 className="mb-4 text-3xl font-bold sm:col-span-2">
          <FormattedMessage id="common.date" />
        </h3>
        <CalendarInput
          className="mb-8 self-start"
          name="startTime"
          label={intl.formatMessage({
            id: "common.startDate",
          })}
          showTime
          stepMinute={30}
          minDate={minDate}
          value={formik.values.startTime}
          onChange={formik.handleChange}
          required
        />

        <CalendarInput
          className="mb-8 self-start"
          name="endTime"
          label={intl.formatMessage({
            id: "common.endDate",
          })}
          value={formik.values.endTime}
          showTime
          stepMinute={30}
          minDate={formik.values.startTime}
          onChange={formik.handleChange}
          required
        />

        <CalendarInput
          className="mb-8 self-start"
          name="registrationOpenTo"
          label={intl.formatMessage({
            id: "common.registrationOpenTo",
          })}
          showTime
          stepMinute={30}
          value={formik.values.registrationOpenTo}
          maxDate={formik.values.startTime}
          onChange={formik.handleChange}
          required
        />

        <CalendarInput
          className="mb-8 self-start"
          id="automaticCancellationAt"
          name="automaticCancellationAt"
          label={intl.formatMessage({
            id: "common.cancellation.date.label",
          })}
          stepMinute={30}
          showTime
          minDate={minDate}
          maxDate={maxCancellationDateTime}
          value={formik.values.automaticCancellationAt}
          onChange={formik.handleChange}
          required
          tooltip={intl.formatMessage({
            id: "common.cancellation.date.tooltip",
            defaultMessage:
              "Om för få spelare har anmält sig så kommer aktiviteten att ställas in vid tidpunkten för automatisk avbokning",
          })}
        />
      </div>

      {create && (
        <div className="gap-x-5 sm:grid md:grid-cols-2 lg:w-1/2">
          <div className="mb-4">
            <Label htmlFor="recurringType" className="mb-1.5 block">
              <FormattedMessage id="common.repeat" />
            </Label>

            <Dropdown
              inputId="recurringType"
              name="recurringType"
              value={formik.values.recurringType}
              onChange={formik.handleChange}
              options={[
                {
                  label: intl.formatMessage({
                    id: "common.never",
                  }),
                  value: "never",
                },
                {
                  label: intl.formatMessage({
                    id: "common.every-day",
                  }),
                  value: "daily",
                },
                {
                  label: intl.formatMessage({
                    id: "common.every-week",
                  }),
                  value: "weekly",
                },
                {
                  label: intl.formatMessage({
                    id: "common.every-other-week",
                  }),
                  value: "biweekly",
                },
                {
                  label: intl.formatMessage({
                    id: "common.every-third-week",
                  }),
                  value: "triweekly",
                },
                {
                  label: intl.formatMessage({
                    id: "common.every-fourth-week",
                  }),
                  value: "quadweekly",
                },
              ]}
            />
          </div>

          {formik.values.recurringType !== "never" && (
            <div className="mb-4">
              <CalendarInput
                className=""
                name="recurringEndDate"
                label={intl.formatMessage({
                  id: "asdsad",
                  defaultMessage: "Upprepas t.o.m.",
                })}
                minDate={formik.values.endTime}
                value={formik.values.recurringEndDate}
                onChange={formik.handleChange}
                error={formik.errors.recurringEndDate}
              />
            </div>
          )}

          {formik.values.recurringType &&
            ["weekly", "biweekly", "triweekly", "quadweekly"].includes(
              formik.values.recurringType,
            ) && (
              <div className="col-span-2">
                <Label htmlFor="" className="mb-1.5 block">
                  <FormattedMessage id="common.weekday" />
                </Label>
                <div className="flex flex-wrap gap-2">
                  {[
                    DayOfWeek.Monday,
                    DayOfWeek.Tuesday,
                    DayOfWeek.Wednesday,
                    DayOfWeek.Thursday,
                    DayOfWeek.Friday,
                    DayOfWeek.Saturday,
                    DayOfWeek.Sunday,
                  ].map(dayOfWeek => {
                    const datetime = DateTime.local({ zone: "utc" }).set({
                      weekday: dayOfWeek,
                    });

                    return (
                      <div key={dayOfWeek} className="relative">
                        <input
                          name="daysOfWeek"
                          type="checkbox"
                          id={dayOfWeek.toString()}
                          value={dayOfWeek}
                          onChange={e => {
                            if (e.target.checked) {
                              formik.setFieldValue("daysOfWeek", [
                                ...(formik.values.daysOfWeek ?? []),
                                dayOfWeek,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "daysOfWeek",
                                formik.values.daysOfWeek?.filter(
                                  item => item !== dayOfWeek,
                                ),
                              );
                            }
                          }}
                          className="peer sr-only"
                          checked={formik.values.daysOfWeek?.includes(
                            dayOfWeek,
                          )}
                        />
                        <label
                          className="flex h-[46px] w-[4.5rem] cursor-pointer select-none items-center justify-center rounded-10 border border-gray-50 transition-colors hover:border-primary peer-checked:border-primary peer-focus-visible:border-current"
                          title={df(datetime, { weekday: "long" })}
                          htmlFor={dayOfWeek.toString()}
                        >
                          <>
                            {df(datetime, {
                              weekday: "short",
                            })}
                          </>
                        </label>
                      </div>
                    );
                  })}
                </div>

                {formik.errors.daysOfWeek && (
                  <div className="mt-1 text-sm text-error">
                    <FormattedMessage id="validation.required.field" />
                  </div>
                )}
              </div>
            )}
        </div>
      )}

      <div className="my-10 border-b border-gray-50" />

      <div className="md:w-1/2 lg:w-1/4">
        <h3 className="mb-4 text-3xl font-bold sm:col-span-2">
          <FormattedMessage id="common.participants" />
        </h3>
        <NumberInput
          className=""
          name="minNumberOfParticipants"
          label={intl.formatMessage({
            id: "activity.admin.create.minNumberOfParticipants",
          })}
          min={1}
          value={formik.values.minNumberOfParticipants}
          onChange={formik.handleChange}
          required
        />
        <NumberInput
          className="mt-8"
          name="numberOfParticipants"
          label={intl.formatMessage({
            id: "activity.admin.create.maxNumberOfParticipants",
          })}
          value={formik.values.numberOfParticipants}
          onChange={formik.handleChange}
          required
        />
      </div>

      <div className="my-10 border-b border-gray-50" />

      <div className="flex flex-col gap-12 sm:gap-8 lg:w-3/4">
        <div className="flex flex-col gap-4">
          <h3 className="text-3xl font-bold">
            <FormattedMessage id="common.price" />
          </h3>
          <p>
            <FormattedMessage id="activity.admin.create.payForActilty.title" />
          </p>

          <InputNumber
            className="w-1/3"
            inputClassName="leading-[36px]"
            name="price"
            maxFractionDigits={2}
            minFractionDigits={2}
            min={0}
            value={formik.values.price}
            onChange={e => {
              formik.setFieldValue("price", e.value || 0);
            }}
          />
        </div>

        <MembershipPrices
          values={formik.values}
          onChange={value => formik.setFieldValue("discountPrices", value)}
        />
      </div>

      <div className="my-10 border-b border-gray-50" />

      <div className="grid gap-x-20 gap-y-10 lg:grid-cols-2">
        {vendorType !== VendorType.None && (
          <div className="space-y-4">
            <h3 className="mb-4 text-3xl font-bold">
              <FormattedMessage id="generalActivities.createActivity.door" />
            </h3>
            <RadioButton
              name="closeDoor"
              checked={isCloseDoor}
              onChange={() => handleDoorConfiguration("closeDoor")}
              label={intl.formatMessage({
                id: "generalActivities.createActivity.closeDoor",
              })}
              disabled={!create}
            />

            {vendorType !== VendorType.Default && (
              <RadioButton
                name="openDoor"
                onChange={() => handleDoorConfiguration("openDoor")}
                checked={isOpenDoor}
                label={intl.formatMessage({
                  id: "generalActivities.createActivity.openDoor",
                })}
                disabled={!create}
              />
            )}

            <RadioButton
              name="isPinCodeEnabled"
              onChange={() => handleDoorConfiguration("enablePinCode")}
              checked={enablePinCode}
              label={intl.formatMessage({
                id: "generalActivities.createActivity.enablePinCode",
              })}
              disabled={!create}
            />

            {enablePinCode && (
              <NumberInput
                className="mt-8 w-1/2"
                name="pinCode"
                label={intl.formatMessage({
                  id: "generalActivities.createActivity.pinCode",
                })}
                value={pinCode === null ? "" : pinCode}
                onChange={e => setPinCode(e.target.value)}
                tooltip={intl.formatMessage({
                  id: "generalActivities.createActivity.pinCode.tooltip",
                })}
                disabled={!create}
                limitInputDigits
                maxLength={4}
                error={enablePinCode && showErrors && formik.errors["pinCode"]}
                required={enablePinCode}
              />
            )}
          </div>
        )}
        <div className="space-y-4">
          <h3 className="mb-4 text-3xl font-bold">
            <FormattedMessage id="activity.admin.create.availability.title" />
          </h3>
          <Checkbox
            name="openForRegistration"
            checked={formik.values.openForRegistration}
            onChange={formik.handleChange}
            label={intl.formatMessage({
              id: "generalActivities.createActivity.openForRegistration",
            })}
          />
          <Checkbox
            name="hidden"
            checked={formik.values.hidden}
            onChange={formik.handleChange}
            label={intl.formatMessage({
              id: "generalActivities.createActivity.hidden.label",
            })}
            disabled={!formik.values.openForRegistration}
          />
        </div>
      </div>

      <div className="my-10 border-b border-gray-50" />

      {/* Sektion 3 */}
      <div>
        <CourtPicker
          facilityId={facilityId}
          defaultCourts={formik.values.courtIdsToPlay}
          onChange={formik.setFieldValue}
          error={showErrors && formik.errors["courtIdsToPlay"]}
        />
      </div>

      <div className="my-10 border-b border-gray-50" />

      <div>
        <h3 className="mb-4 text-3xl font-bold">
          <FormattedMessage id="admin.event-coordinators" />
        </h3>

        <CoordinatorSelector
          defaultValue={initialValues.coordinators}
          onChange={users => formik.setFieldValue("coordinators", users)}
        />
      </div>

      <div className="mb-10"></div>
      {showSave && (
        <div className="flex justify-end">
          <Button
            disabled={formik.isSubmitting}
            type="primary"
            size="small"
            onClick={() => {
              setShowErrors(true);
              formik?.submitForm();
            }}
            text={intl.formatMessage({
              id: create
                ? "admin.activities.event.create"
                : "admin.activities.event.save",
            })}
          >
            {formik.isSubmitting && <ProgressSpinner fontSize="1rem" />}
          </Button>
        </div>
      )}
    </form>
  );
};
