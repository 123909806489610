import { Fragment, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { DateTime } from "luxon";

import { DateOnly } from "../../../../../../../models/DateOnly";
import {
  GenderType,
  RecurringBookingType,
} from "../../../../../../../models/common";
import type { AvailableSlots } from "../../../../../../../modules/checkout/models/Calendar";
import {
  GeneralActivities,
  GeneralActivityRequest,
} from "../../../../../../../modules/game/models/GeneralActivities";

import { eventSelectableImages } from "../../../../../../../helpers/activityHelper";
import { adminGetActivitiesPath } from "../../../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import {
  createGeneralActivity,
  createRecurringEvent,
  getAvailabilityOfRecurringEvents,
} from "../../../../../../../modules/game/services/GeneralActivities";

import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";
import { ImageSelector } from "../../../../../../../components/image/ImageSelector/ImageSelector";

import { Form } from "../Form";

interface Props {
  facilityId: string;
  prefilledValues?: GeneralActivities;
  startDate?: DateOnly;
  onCreate: (activityId: string, image?: string | File) => void;
}

export const CreateForm: React.FC<Props> = ({
  facilityId,
  prefilledValues,
  startDate,
  onCreate,
}: Props) => {
  const preSelectedImage = useMemo(
    () =>
      prefilledValues?.imageUrl ||
      eventSelectableImages[
        Math.floor(Math.random() * eventSelectableImages.length)
      ],
    [prefilledValues?.imageUrl],
  );

  const intl = useIntl();
  const [newImage, setNewImage] = useState<string | File>(preSelectedImage);
  const { toastError } = useToaster();
  const history = useHistory();
  const { df } = useDateFormat(facilityId);

  const [availableSlots, setAvailableSlots] = useState<AvailableSlots[]>();
  const [createEventRequest, setCreateEventRequest] =
    useState<GeneralActivityRequest>();
  const [isLoading, setIsLoading] = useState(false);

  const startTime = startDate
    ? startDate.toDateTime().setZone("local").set({ hour: 12 }).startOf("hour")
    : DateTime.now().set({ hour: 12 }).startOf("hour").plus({ days: 1 });

  const initialValues: GeneralActivityRequest = {
    facilityId,
    name: prefilledValues?.name ?? "",
    description: prefilledValues?.description ?? "",
    gender: prefilledValues?.gender ?? GenderType.Mix,
    startTime: startTime,
    endTime: startTime.plus({ hour: 1 }),
    price: prefilledValues?.price.valueInclTax ?? 0,
    numberOfParticipants: prefilledValues?.numberOfParticipants ?? 1,
    registrationOpenTo: startTime.minus({ hour: 2 }),
    courtIdsToPlay: prefilledValues?.courtIdsToPlay ?? [],
    openDoor: prefilledValues?.openDoor ?? false,
    isPinCodeEnabled: prefilledValues?.isPinCodeEnabled ?? true,
    pinCode: prefilledValues?.pinCode ?? "",
    openForRegistration: false,
    hidden: false,
    automaticCancellationAt: startTime.minus({ hour: 1 }),
    minNumberOfParticipants: prefilledValues?.minNumberOfParticipants ?? 0,
    priority: prefilledValues?.priority ?? null,
    coordinators: prefilledValues?.coordinators || [],
    discountPrices: prefilledValues?.discountPrices.length
      ? prefilledValues?.discountPrices.map(discount => ({
          discountAmount: discount.price.valueInclTax,
          membershipIds: discount.membershipIds,
        }))
      : [{ discountAmount: 0, membershipIds: [] }],
    minSkillLevel: prefilledValues?.minSkillLevel ?? 1,
    maxSkillLevel: prefilledValues?.maxSkillLevel ?? 10,

    // recurring stuff
    recurringType: "never",
    daysOfWeek: [],
    recurringEndDate: undefined,
  };

  const submit = async (data: GeneralActivityRequest, onError: () => void) => {
    const cleanedData = {
      ...data,
      discountPrices: data.discountPrices.filter(
        discount => discount.membershipIds.length > 0,
      ),
    };

    try {
      if (data.recurringType !== "never") {
        console.error("nu blir det recurring events..");

        const recurringData = {
          ...data,
          recurringGroupType:
            data.recurringType === "daily"
              ? RecurringBookingType.Daily
              : data.recurringType !== "never"
                ? RecurringBookingType.Weekly
                : undefined,
          interval: data.recurringType
            ? recurringTypeToInterval(data.recurringType)
            : undefined,
          startDate: data.startTime,
          endDate: data.recurringEndDate,
        };

        const slots = await getAvailabilityOfRecurringEvents(recurringData);

        const isAllAvailable = slots.every(({ isAvailable }) => isAvailable);

        if (isAllAvailable) {
          await createRecurringEvent(recurringData);

          history.replace(adminGetActivitiesPath());
        } else {
          setAvailableSlots(slots);
          setCreateEventRequest(recurringData);
        }
      } else {
        const res = await createGeneralActivity(cleanedData);

        if (res?.isSuccessful && res?.data?.id) {
          onCreate(res.data.id, newImage);
        } else {
          onError?.();
          toastError.createActivityFailed();
        }
      }
    } catch {
      onError?.();
      toastError.createActivityFailed();
    }
  };

  if (!facilityId) {
    return null;
  }

  return (
    <>
      <div>
        <Form
          facilityId={facilityId}
          hasChange={!!prefilledValues || !!newImage}
          create
          initialValues={initialValues}
          imageSelector={
            <ImageSelector
              className="w-full max-w-[415px] sm:w-2/5"
              onSelect={setNewImage}
              pickerTitle={intl.formatMessage({
                id: "activity.image.selector.title",
              })}
              pickerDescription={intl.formatMessage({
                id: "activity.image.selector.description",
              })}
              imgSrc={preSelectedImage}
              selectableImages={eventSelectableImages}
            />
          }
          onSubmit={submit}
        />
      </div>

      {availableSlots && (
        <ConfirmationDialog
          visible
          onHide={() => {
            setAvailableSlots(undefined);
            setCreateEventRequest(undefined);
            setIsLoading(false);
          }}
          title={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.title",
          })}
          onSubmit={async () => {
            if (!createEventRequest) {
              return;
            }

            setIsLoading(true);

            try {
              await createRecurringEvent(createEventRequest);

              history.replace(adminGetActivitiesPath());
            } catch {
              toastError.createActivityFailed();
              setIsLoading(false);
            }
          }}
          onCancel={() => {
            setAvailableSlots(undefined);
            setCreateEventRequest(undefined);
            setIsLoading(false);
          }}
          confirmText={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.confirm",
          })}
          denyText={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.deny",
          })}
          loading={isLoading}
        >
          <div className="mb-14 mt-8 flex justify-center">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4 text-left">
              {availableSlots.map(slot => (
                <Fragment key={slot.startTime.toJSON()}>
                  <div>
                    <p className="text-gray-700">
                      <FormattedMessage id="common.date-and-time" />
                    </p>
                    <span className="font-semibold">
                      {df(slot.startTime, DateTime.DATETIME_MED)} -{" "}
                      {df(slot.endTime, DateTime.TIME_SIMPLE)}
                    </span>
                  </div>
                  <span
                    className={clsx(
                      "place-self-end rounded-lg px-2 font-semibold",
                      slot.isAvailable
                        ? "bg-green-700/10 text-green-700"
                        : "bg-red-600/10 text-red-600",
                    )}
                  >
                    {slot.isAvailable ? (
                      <FormattedMessage id="admin.calendar.recurring.unavailable-dialog.slot.available" />
                    ) : (
                      <FormattedMessage id="admin.calendar.recurring.unavailable-dialog.slot.occupied" />
                    )}
                  </span>
                </Fragment>
              ))}
            </div>
          </div>
        </ConfirmationDialog>
      )}
    </>
  );
};

const recurringTypeToInterval = (recurringType: string) => {
  switch (recurringType) {
    default:
    case "daily":
      return 1;
    case "weekly":
      return 1;
    case "biweekly":
      return 2;
    case "triweekly":
      return 3;
    case "quadweekly":
      return 4;
  }
};
