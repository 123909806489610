import { DateTime } from "luxon";

import { type ApiResponse, ApiSingleResponse } from "../../../models/common";
import type { AvailableSlots } from "../../checkout/models/Calendar";
import { Payment, PaymentInfo } from "../../checkout/models/Payment";
import type { AvailableSlotsResponse } from "../../checkout/models/api/calendar";
import type { User } from "../../player/models/User";
import {
  GeneralActivities,
  GeneralActivitiesApiResponse,
  GeneralActivityRequest,
} from "../models/GeneralActivities";

import { dateTimeToUtcIsoSanitizedToMinutes } from "../../../helpers/dateTime";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "game/v4";

export const getGeneralActivity = (
  activityId: string,
  include?: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<GeneralActivities>> => {
  return fetchApi({
    uri: `${apiVersion}/generalactivities/${activityId}?include=${include}`,
    signal,
  })
    .then((data: ApiSingleResponse<GeneralActivitiesApiResponse>) => {
      return Promise.resolve({
        ...data,
        data: formatGeneralActivitiesFromApi(data.data),
      });
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const registerToGeneralActivity = (
  activityId: GeneralActivities["id"],
  userId: User["id"],
  sendEmailToUser = true,
): Promise<ApiSingleResponse<unknown>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/generalactivities/${activityId}/register`,
    payload: {
      registrateUserId: userId,
      sendRegistrationConfirmationEmail: !!sendEmailToUser,
    },
  })
    .then(data => {
      return Promise.resolve(data as ApiSingleResponse<unknown>);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const deleteGeneralActivity = (
  activityId: string,
): Promise<ApiSingleResponse<unknown>> => {
  return fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/generalactivities/${activityId}`,
  })
    .then(data => {
      return Promise.resolve(data as ApiSingleResponse<unknown>);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const createGeneralActivity = async (
  data: GeneralActivityRequest,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<GeneralActivities> | null> => {
  try {
    const payload = {
      ...data,
      startTime: dateTimeToUtcIsoSanitizedToMinutes(data.startTime),
      endTime: dateTimeToUtcIsoSanitizedToMinutes(data.endTime),
      registrationOpenTo: dateTimeToUtcIsoSanitizedToMinutes(
        data.registrationOpenTo,
      ),
      automaticCancellationAt: dateTimeToUtcIsoSanitizedToMinutes(
        data.automaticCancellationAt,
      ),
      coordinatorIds: data.coordinators.map(coordinator => coordinator.id),
    };

    const response = (await fetchApi<
      ApiSingleResponse<GeneralActivitiesApiResponse>
    >({
      method: "POST",
      uri: `${apiVersion}/generalactivities`,
      payload,
      signal,
    })) as ApiSingleResponse<GeneralActivitiesApiResponse>;

    return {
      ...response,
      data: formatGeneralActivitiesFromApi(response.data),
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateGeneralActivity = async (
  data: GeneralActivityRequest,
  signal?: AbortSignal,
): Promise<GeneralActivities | null> => {
  try {
    const payload = {
      ...data,
      startTime: dateTimeToUtcIsoSanitizedToMinutes(data.startTime),
      endTime: dateTimeToUtcIsoSanitizedToMinutes(data.endTime),
      registrationOpenTo: dateTimeToUtcIsoSanitizedToMinutes(
        data.registrationOpenTo,
      ),
      automaticCancellationAt: data.automaticCancellationAt
        ? dateTimeToUtcIsoSanitizedToMinutes(data.automaticCancellationAt)
        : "",
      coordinatorIds: data.coordinators.map(coordinator => coordinator.id),
    };

    const response = (await fetchApi<
      ApiSingleResponse<GeneralActivitiesApiResponse>
    >({
      method: "PUT",
      uri: `${apiVersion}/generalactivities/${data.id}`,
      payload,
      signal,
    })) as ApiSingleResponse<GeneralActivitiesApiResponse>;
    return formatGeneralActivitiesFromApi(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateGeneralActivitesRegistrationStatus = (
  activityId: string,
  status: boolean,
): Promise<ApiSingleResponse<unknown>> => {
  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/generalactivities/${activityId}/openforregistration`,
    payload: { openForRegistration: status },
  })
    .then(data => {
      return Promise.resolve(data as ApiSingleResponse<unknown>);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const payForGeneralActivities = async (
  activityId: string,
  paymentMethod: string,
  data?: PaymentInfo,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<Payment>>({
      method: "POST",
      uri: `${apiVersion}/generalactivities/${activityId}/pay/${paymentMethod}`,
      payload: data,
      signal,
    })) as ApiSingleResponse<Payment>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const markGeneralActivityAsPaid = async (
  activityId: string,
  userId: string,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<Payment>>({
      method: "POST",
      uri: `${apiVersion}/generalactivities/admin/markaspaid/${activityId}/${userId}`,
      signal,
    })) as ApiSingleResponse<Payment>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendMessageToEventParticipants = async (
  eventId: GeneralActivities["id"],
  data: {
    subject: string;
    content: string;
    isSelectedAll: boolean;
    participantList: GeneralActivities["registratedUsers"][number]["id"][];
  },
  signal?: AbortSignal,
): Promise<boolean> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/generalactivities/sendemailstoparticipants/${eventId}`,
      signal,
      payload: data,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createRecurringEvent = async (
  data: GeneralActivityRequest,
  signal?: AbortSignal,
): Promise<GeneralActivities | null> => {
  try {
    const payload = {
      ...data,
      startTime: dateTimeToUtcIsoSanitizedToMinutes(data.startTime),
      endTime: dateTimeToUtcIsoSanitizedToMinutes(data.endTime),
      registrationOpenTo: dateTimeToUtcIsoSanitizedToMinutes(
        data.registrationOpenTo,
      ),
      automaticCancellationAt: data.automaticCancellationAt
        ? dateTimeToUtcIsoSanitizedToMinutes(data.automaticCancellationAt)
        : "",
      coordinatorIds: data.coordinators.map(coordinator => coordinator.id),
    };

    const response = (await fetchApi<
      ApiSingleResponse<GeneralActivitiesApiResponse>
    >({
      method: "POST",
      uri: `${apiVersion}/generalactivities/recurring`,
      payload,
      signal,
    })) as ApiSingleResponse<GeneralActivitiesApiResponse>;

    return formatGeneralActivitiesFromApi(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAvailabilityOfRecurringEvents = async (
  request: GeneralActivityRequest,
): Promise<AvailableSlots[]> => {
  try {
    const response = (await fetchApi({
      uri: `${apiVersion}/generalactivities/recurring/availability`,
      method: "POST",
      payload: {
        ...request,
        startTime: dateTimeToUtcIsoSanitizedToMinutes(request.startTime),
        endTime: dateTimeToUtcIsoSanitizedToMinutes(request.endTime),
        registrationOpenTo: dateTimeToUtcIsoSanitizedToMinutes(
          request.registrationOpenTo,
        ),
        automaticCancellationAt: request.automaticCancellationAt
          ? dateTimeToUtcIsoSanitizedToMinutes(request.automaticCancellationAt)
          : "",
        coordinatorIds: request.coordinators.map(coordinator => coordinator.id),
      },
    })) as ApiResponse<AvailableSlotsResponse>;

    return response.data.map(({ startTime, endTime, ...rest }) => ({
      ...rest,
      startTime: DateTime.fromISO(startTime, { setZone: true }),
      endTime: DateTime.fromISO(endTime, { setZone: true }),
    }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteRecurringEventsSeries = async (
  recurringActivityId: string,
) =>
  fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/generalactivities/recurring/${recurringActivityId}`,
  }).catch(err => {
    return Promise.reject(err);
  });

function formatGeneralActivitiesFromApi(
  generalActivities: GeneralActivitiesApiResponse,
): GeneralActivities {
  return {
    ...generalActivities,
    startTime: DateTime.fromISO(generalActivities.startTime, { setZone: true }),
    endTime: DateTime.fromISO(generalActivities.endTime, { setZone: true }),
    registrationOpenTo: DateTime.fromISO(generalActivities.registrationOpenTo, {
      setZone: true,
    }),
    automaticCancellationAt: DateTime.fromISO(
      generalActivities.automaticCancellationAt,
    ),
    registratedUsers: formatRegistratedUsersFromApi(
      generalActivities.registratedUsers,
    ),
  };
}

function formatRegistratedUsersFromApi(
  users: GeneralActivitiesApiResponse["registratedUsers"],
): GeneralActivities["registratedUsers"] {
  return users.map(user => {
    return {
      ...user,
      lastModified: user.lastModified
        ? DateTime.fromISO(user.lastModified, { setZone: true })
        : null,
    };
  });
}
